function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import PropTypes from "prop-types";
import React from "react";
import { CommonProps, LineHelpers } from "victory-core";
import { useCanvasContext } from "./hooks/use-canvas-context";

var CanvasCurve = function (props) {
  var _useCanvasContext = useCanvasContext(),
      canvasRef = _useCanvasContext.canvasRef,
      clear = _useCanvasContext.clear,
      clip = _useCanvasContext.clip;

  var style = props.style,
      data = props.data;
  var stroke = style.stroke,
      strokeWidth = style.strokeWidth;
  var draw = React.useCallback(function (ctx) {
    var line = LineHelpers.getLineFunction(props);
    ctx.strokeStyle = stroke;
    ctx.lineWidth = strokeWidth;
    line.context(ctx)(data);
    ctx.stroke();
  }, [data, props, stroke, strokeWidth]);
  React.useEffect(function () {
    var ctx = canvasRef.current.getContext("2d");
    clear(ctx);
    draw(ctx);
    clip(ctx);
  }, [canvasRef, draw, clear, clip]);
  return null;
};

CanvasCurve.propTypes = _objectSpread(_objectSpread({}, CommonProps.primitiveProps), {}, {
  interpolation: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  openCurve: PropTypes.bool,
  origin: PropTypes.object,
  polar: PropTypes.bool
});
export default CanvasCurve;